import React from "react"
// import { CSSTransition } from 'react-transition-group';
import Modal from 'react-modal';
// import { Pannellum } from "pannellum-react";


// import centralInformalLearning from '../../_images/mcarthy-hall-vr-panoramas/central-informal-learning.jpg';
// import officeCanyon from '../../_images/mcarthy-hall-vr-panoramas/office-canyon.jpg';
// import seInformalLearning from '../../_images/mcarthy-hall-vr-panoramas/se-informal-learning.jpg';
// import swHall from '../../_images/mcarthy-hall-vr-panoramas/sw-hall.jpg';
// import terrace from '../../_images/mcarthy-hall-vr-panoramas/terrace.jpg';
// import centralInformalLearningThumb from '../../_images/mcarthy-hall-vr-panoramas/central-informal-learning-thumb.jpg';
// import officeCanyonThumb from '../../_images/mcarthy-hall-vr-panoramas/office-canyon-thumb.jpg';
// import seInformalLearningThumb from '../../_images/mcarthy-hall-vr-panoramas/se-informal-learning-thumb.jpg';
// import swHallThumb from '../../_images/mcarthy-hall-vr-panoramas/sw-hall-thumb.jpg';
// import terraceThumb from '../../_images/mcarthy-hall-vr-panoramas/terrace-thumb.jpg';

import MHRenovation1 from '../../_images/mcarthy-hall-renovation/mccarthy-hall-1.jpg';
import MHRenovation2 from '../../_images/mcarthy-hall-renovation/mccarthy-hall-2.jpg';
import MHRenovation3 from '../../_images/mcarthy-hall-renovation/mccarthy-hall-3.jpg';
import MHRenovation4 from '../../_images/mcarthy-hall-renovation/mccarthy-hall-4.jpg';
import MHRenovation5 from '../../_images/mcarthy-hall-renovation/mccarthy-hall-5.jpg';
import MHRenovation1thumb from '../../_images/mcarthy-hall-renovation/mccarthy-hall-1-thumb.jpg';
import MHRenovation2thumb from '../../_images/mcarthy-hall-renovation/mccarthy-hall-2-thumb.jpg';
import MHRenovation3thumb from '../../_images/mcarthy-hall-renovation/mccarthy-hall-3-thumb.jpg';
import MHRenovation4thumb from '../../_images/mcarthy-hall-renovation/mccarthy-hall-4-thumb.jpg';
import MHRenovation5thumb from '../../_images/mcarthy-hall-renovation/mccarthy-hall-5-thumb.jpg';


import closeIcon from '../../_images/icons/white/close.svg';
import arrowRight from '../../_images/icons/white/arrow-right.svg';
import arrowLeft from '../../_images/icons/white/arrow-left.svg';
import newWindow from '../../_images/icons/black/new-window.svg';


import './style.scss';


Modal.setAppElement('#___gatsby')

const panoramas = [
  {
    // title: 'Central Informal Learning',
    image: MHRenovation1,
    thumb: MHRenovation1thumb
  },
  {
    // title: 'Office \'Canyon\'',
    image: MHRenovation2,
    thumb: MHRenovation2thumb
  },
  {
    // title: 'South East Informal Learning',
    image: MHRenovation3,
    thumb: MHRenovation3thumb
  },
  {
    // title: 'South West Hall',
    image: MHRenovation4,
    thumb: MHRenovation4thumb
  },
  {
    // title: 'Terrace',
    image: MHRenovation5,
    thumb: MHRenovation5thumb
  }
]

export default class McCarthyHallVrPanoramas extends React.Component {
  constructor() {
    super();
    this.state = {
      modalIsOpen: false,
      currentImage: '',
      currentIndex: ''
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.modalNavPrev = this.modalNavPrev.bind(this);
    this.modalNavNext = this.modalNavNext.bind(this);
  }

  openModal(image,index) {
    this.setState({modalIsOpen: true});
    this.setState({currentImage: image});
    this.setState({currentIndex: index});
    // console.log(index)
  }

  closeModal() {
    this.setState({modalIsOpen: false});
    this.setState({currentImage: ''});
    this.setState({currentIndex: ''});
  }

  modalNavPrev() {

    var pos = this.state.currentIndex;
    var prevPos = pos - 1;

    if (pos == '') {
      var prevPos = 4;
    } else if (pos == 4) {
      var prevPos = pos - 1;
    }

    this.setState({currentImage: panoramas[prevPos]['image']});
    this.setState({currentIndex: prevPos});

  }

  modalNavNext() {

    var pos = this.state.currentIndex;
    var nextPos = pos + 1;

    if (pos == '') {
      var nextPos = pos + 1;
    } else if (pos == 4) {
      var nextPos = 0;
    }

    this.setState({currentImage: panoramas[nextPos]['image']});
    this.setState({currentIndex: nextPos});
  }

  render() {
    var self = this
    function PanoList() {
      var list = panoramas.map(function(pano,index) { 
        return (
          <li>
            <button className="ctaButton" onClick={() => self.openModal(pano.image,index)}>
              <img src={pano.thumb} alt="" className="thumb" />
              {/* <span className="title">{pano.title}</span> */}
            </button>
          </li>
        )
      })
    
      return (
        <ul className="panorama-list">
          {list}
        </ul>
      )
    }

    return (
      <React.Fragment>
      
      {/* <h2>Tour the modernized facilities using virtual reality:</h2> */}
      <PanoList />

      <Modal
        closeTimeoutMS={400}
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Donate Now"
        className="modal vr-panoramas"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={true}
      >
        <button onClick={this.closeModal} id="closeButton"><img src={closeIcon} alt="" /> Close</button>
        <button onClick={this.modalNavPrev} id="prevButton" className="modalNav" title="Previous Item"><img src={arrowLeft} className="icon" alt="Previous Item" /></button>
        <button onClick={this.modalNavNext} id="nextButton" className="modalNav" title="Next Item"><img src={arrowRight} className="icon" alt="Next Item" /></button>
        {/* <Pannellum
          width="100%"
          height="500px"
          image={this.state.currentImage}
          pitch={10}
          yaw={180}
          hfov={110}
          autoLoad
        /> */}
        <img src={this.state.currentImage} />
      </Modal>
      </React.Fragment>
    );
  }
}