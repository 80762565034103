import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'
import Layout from "../components/layout";
// import Img from "gatsby-image";
import { Link } from "gatsby"
import { withPrefix } from 'gatsby'
// import Cards from '../components/card';
import DOD from '../components/dods';
import CollegeName from '../components/collegeName';
import HeroBanner from '../components/herobanner';
import Form from '../components/keep-me-informed-form';
import useSiteMetadata from '../components/use-site-metadata';
import McCarthyHallVrPanoramas from '../components/mccarthy-hall-vr-panoramas/index';
// import McCarthyHallVideo from '../components/mccarthy-hall-vr-panoramas/video';


import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share';

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon
} from 'react-share';

export default function Template({
  data
}) {

  const { siteUrl } = useSiteMetadata();

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  
  // var tags = frontmatter.tags.slice(1).map(function(tag){
  //   var path = '/' + tag.replace(/\s+/g, '-').toLowerCase();
  //   if (tag !== 'featured') {
  //     return (
  //       <Link to={path} className="tag">{tag}</Link>
  //     )
  //   } else {
  //     return null;
  //   }
  // })
  
  // if (frontmatter.goalAmount) {
  //   var goal = <span className="goal">{frontmatter.goalAmount}</span>;
  // } else {
  //   goal = null;
  // }

  var pageClass = frontmatter.path;
  pageClass = pageClass.split("/").pop();

  var alt = 'Photo of ' + frontmatter.title;

  var desc = html.replace(/(<([^>]+)>)/ig,"");

  // if (frontmatter.tags[0] == 'comm' || frontmatter.tags[0] == 'students') {

  //   var link = null;
    
  // } else {

  //   if (frontmatter.givingLink) {
  //     var link = frontmatter.givingLink;
  //   } else {
  //     var link = null;
  //   }

  // }

  // All giving links will now be a NULL value
  // No longer directing users to BBNC giving forms
  var link = null;



  if (frontmatter.path == '/nsm/transform-facilities') {
    // var ctaText = "I Want to Help";
    var contactRe = "to discuss your interest in the McCarthy Hall project"
  } else {
    // var ctaText = "Donate Now";
    var contactRe = null;
  }
  
  var ctaText = "I Want to Help";

  function LearnMorePDf() {

    if (frontmatter.path == '/nsm/transform-facilities') {
      return  (

        <React.Fragment>
          {/* <McCarthyHallVideo /> */}
          <h2>How You Can Help</h2>
          <a href={ withPrefix('/pdf/McCarthy-Hall-Naming.pdf') } target="_blank" className="buttonBlue">Donor Recognition Opportunities</a>
          {/* <a href={ withPrefix('/pdf/McCarthy-Hall-Prospectus.pdf#page=9') } target="_blank" className="buttonBlue">Donor Recognition Opportunities</a> */}
          <br /><br />
        </React.Fragment>

      )
    } else {
      return null;
    }

  }

  function VRpanoramas() {

    if (frontmatter.path == '/nsm/transform-facilities') {
      return  (

        <McCarthyHallVrPanoramas />

      )
    } else {
      return null;
    }

  }

  return (
    <Layout>
      <Helmet>
        <title>{frontmatter.title}</title>
        <body className={"areas " + pageClass} />
        {/* <script type="text/javascript" src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5e45dd5a51f0ce11"></script> */}

        <link rel="canonical" href={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta name="description" content={frontmatter.desc} />

        {/* Schema.org markup for Google+ */}
        <meta itemprop="name" content={frontmatter.title} />
        <meta itemprop="description" content={frontmatter.desc} />
        <meta itemprop="image" content={frontmatter.featuredImage.childImageSharp.fixed.src} />

        {/* Twitter Card data */}
        <meta name="twitter:title" content={frontmatter.title} />
        <meta name="twitter:description" content={frontmatter.desc} />
        <meta name="twitter:image:src" content={frontmatter.featuredImage.childImageSharp.fixed.src} />

        {/* Open Graph data */}
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:url" content={`${siteUrl}${frontmatter.path || "/"}`} />
        <meta property="og:image" content={frontmatter.featuredImage.childImageSharp.fixed.src} />
        <meta property="og:image:alt" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.desc} />
      </Helmet>
      {/* <section>
        <div className="wrap">
          <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} alt={alt} />
        </div>
      </section> */}
      {/* <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} alt={alt} /> */}
      {/* <img src={frontmatter.featuredImage.childImageSharp.fluid.src} alt={alt} style={{width: '100%'}} /> */}
      {/* <div id="areasBanner" style={{
        height: '450px',
        backgroundColor: '#e1e1e1',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(${frontmatter.featuredImage.childImageSharp.fluid.src})`
      }} >
        <div>
        <CollegeName abrv={frontmatter.tags[0]} />
          <h1 className="animated fadeInDown">{frontmatter.title}</h1>
          <a href="#" className="button orange">Donate Now</a>
        </div>
      </div> */}
      <HeroBanner
        image={frontmatter.featuredImage.childImageSharp.fluid.src}
        alt={alt}
        heading={frontmatter.title}
        ctaLink={link}
        ctaText={ctaText}
        college={frontmatter.tags[0]}
        contactreason={contactRe}
        extra={frontmatter.extrabutton}
        extraLink={frontmatter.extrabuttonLink}
      />
      <section>
        <article className="wrap">
          <div className="row">
            <div className="col-8">
              <VRpanoramas />
              <h2 class="sub-head">{frontmatter.subHead}</h2>
              <p style={{padding: 0}}><strong>Your investment will:</strong></p>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className="col-4">
              <LearnMorePDf />

              {/* <a style={{ marginBottom: '10px' }} href="#" className="buttonBlue outline">Share this project</a> */}
              <strong>Share this project:</strong>
              {/* <div style={{margin: '10px auto 1px auto'}} class="addthis_inline_share_toolbox"></div> */}
              <div className="sharing">
                <FacebookShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <FacebookIcon
                  aria-label="Facebook"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <TwitterIcon
                  aria-label="Twitter"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </TwitterShareButton>
                <LinkedinShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <LinkedinIcon
                  aria-label="Linkedin"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </LinkedinShareButton>        
                <EmailShareButton
                  url={`${siteUrl}${frontmatter.path || "/"}`}>
                  <EmailIcon
                  aria-label="Email"
                  size={38}
                  borderRadius={0}
                  iconFillColor="#084b8a"
                  bgStyle={{
                    fill: 'white'
                  }} />
                </EmailShareButton>          
              </div>

              <Form profile={frontmatter.path} college={<CollegeName abrv={frontmatter.tags[0]} />} />
              {/* <a style={{ marginBottom: '30px' }} href="#" className="buttonBlue outline">Keep me informed</a> */}


              <h2 style={{ marginTop: '30px' }}>Contact</h2>
              <DOD layout="2" college={frontmatter.tags[0]} />

            </div>
          </div>
        </article>
      </section>
      

      {/* <section style={{ backgroundColor: '#EAECEC', padding: '10px 0 50px 0' }}> 
        <h2 class="center">Other Ways to Help <CollegeName abrv={frontmatter.tags[0]} /></h2>
        <Cards tag={frontmatter.tags[0]} exclude={frontmatter.path} />
      </section> */}

      {/* <section style={{ backgroundColor: '#dddddd', padding: '10px 0 50px 0' }}> 
        <h2 class="center">Other Ways to Help {frontmatter.tags[1]}</h2>
        <Cards tag={frontmatter.tags[1]} exclude={frontmatter.path} />
      </section> */}
      
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        featuredImage{
          publicURL
          childImageSharp {
            fluid(maxWidth: 1920, quality: 70) {
              src
            }
            fixed(width: 1200, height: 630, fit: COVER, cropFocus: ATTENTION, quality: 50) {
              src
            }
          }
        }
        path
        title
        tags
        goalAmount
        subHead
        givingLink
        desc
        extrabutton
        extrabuttonLink
      }
    }
  }
`