export default (props) => {
  var collegeName;

  if (props.abrv === 'arboretum') {
    collegeName = 'the Fullerton Arboretum';
  } else if (props.abrv === 'arts') {
    collegeName = 'the College of the Arts';
  } else if (props.abrv === 'athletics') {
    collegeName = 'Titan Athletics';
  } else if (props.abrv === 'comm') {
    collegeName = 'the College of Communications';
  } else if (props.abrv === 'ecs') {
    collegeName = 'the College of Engineering and Computer Science';
  } else if (props.abrv === 'education') {
    collegeName = 'the College of Education';
  } else if (props.abrv === 'hhd') {
    collegeName = 'the College of Health and Human Development';
  } else if (props.abrv === 'hss') {
    collegeName = 'the College of Humanities and Social Sciences';
  } else if (props.abrv === 'cbe') {
    collegeName = 'the College of Business and Economics';
  } else if (props.abrv === 'nsm') {
    collegeName = 'the College of Natural Sciences & Mathematics';
  } else if (props.abrv === 'students') {
    collegeName = 'Student Affairs';
  }
  return collegeName
} 


